@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Roboto:wght@300;400;500;700&display=swap");

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior:unset;
  font-family: 'Inter' , "Roboto" , sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1d8ad5;
}



html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter' , "Roboto" , sans-serif;
  min-height: 100vh;
}

.Toastify__toast-body {
  font-size: 1.4rem;
  font-family: 'Inter';
}

.hide {
  display: none !important;
}

.vision-hidden {
  visibility: hidden !important;
}

.show {
  display: block;
}


.center-box {
  width: 100%;
  max-width: 700px;
  min-height: 700px;
  display: grid;
  place-items: center;
  background-color: #ffffff;
  font-size: 2rem;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 50%;
  transform: translate(-50%, 5%);
  border-radius: 10px;
}

.center-box .close-btn {
  display: block;
  padding: 1rem 2rem;
  position: absolute;
  top: 0;
  text-align: left;
  justify-self: end;
  cursor: pointer;
}

.center-box label {
  margin: 10px 0;
  display: block;
}

.center-box form {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  margin: 2rem 0;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px dashed #000;
}

.center-box .input-field input,
.center-box .input-field select,
.center-box .input-field textarea {
  padding: 18px 20px;
  margin: 0;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 1.4rem;
  color: #000;
  font-family: 'Inter', 'Roboto', sans-serif;
  font-weight: 500;
  background-color: #E0E1EA;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);;
}

.center-box .input-field input::placeholder {
  color: #000;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: 'Inter', 'Roboto', sans-serif;
}

select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
}

.button {
  margin-top: 15px;
  width: 100%;
  background-color: #000;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: 200ms ease-out;
}

.emailPage-buttons .button:hover {
  background-color: #000000d8;
}
.button.flex:hover {
  background-color: #000000d8;
}

.button.buttonView:hover {
  background-color: #2a3990d8;
}

.button.red:hover {
  background-color: #9C254Dd8;
}

.button.green:hover {
  background-color: rgba(0, 128, 0, 0.76);
}


.button-loading {
  display: flex;
  align-items: center;
  min-height: 75px;
}

.button-loading button {
  margin-top: 0;
}

textarea {
  min-height: 200px;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
}


.grid-box {
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
}

.overlay {
  height: 0;
}

.overlay.blur {
  position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px); */
    z-index: 90;
}

.navigation {
  display: flex;
  justify-content: center;
  align-items:flex-start;
}

.navigation .navigation-bar {
  position: fixed;
  z-index: 99999;
}

.navigation .navigation-bar .category {
  margin-top: 2.5rem;
  font-size: 24px;
  color: #5a5a5a;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.navigation .navigation-bar .category p {
  cursor: pointer;
}


.image-holder img {
  max-width: 60px;
}

.grid-main {
  width: 100%;
  height: 100vh;
  max-height: 100%;
  min-height: 700px;
  display: grid;
  place-items: center;
  padding: 0 2rem;
  overflow: hidden;
}

.grid-main .container {
  max-width: 1300px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 10px;
  overflow: hidden;
}


.grid-main .container .picture img {
  width: 100%;
  display: block;
}

.grid-main .container .signup {
  background-color: #F4F4F4;
  padding: 0 7.5rem;
}

.grid-main .container .signup .input-container {
  margin-top: 9rem;
  width: 100%;
}

@media screen and (max-width: 1200px) {

  .grid-main .container .signup {
    padding: 0 4.5rem;
    display: grid;
    place-items: center;
    
  }

  .grid-main .container .signup .input-container {
    margin-top: 0rem;
    padding: 1.5rem 0;

  }

}

@media screen and (max-width: 1000px) { 


  .grid-main {
    height: 100%;
    padding: 1rem;
  }

  .grid-main .container { 
    grid-template-columns: 1fr;
  }

  .grid-main .container .picture { 
    grid-row: 2;
  }

  .grid-main .container .signup {
    padding: 1rem;    
  }

  .grid-main .container .signup .input-container {
    margin-top: 3.5rem;
    margin-bottom: 4.5rem;
  }

  .grid-main .register .signup .input-container {
    padding-bottom: 0 !important;
  }

  .grid-main .register .pictures p, .grid-main .register .pictures ul {
    margin: 0 3rem 0 4.5rem !important;
    list-style-position: outside !important;
}

}


.grid-main .container .signup .input-container .picture img {
  max-width: 60px;
  display: block;
  margin: 0 auto;
}

.grid-main .container .signup .input-container .title {
  margin-top: 2.5rem;
  margin-bottom: 3.5rem;
  font-size: 40px;
  text-align: center;
}

.grid-main .container .signup .input-container .form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.grid-main .container .signup .input-container .form-container .input-box {
  padding: 18px 20px;
  margin: 0;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 1.4rem;
  color: #000;
  font-family: 'Inter', 'Roboto', sans-serif;
  font-weight: 500;
  background-color: #E0E1EA;
  border-radius: 5px;
}

.grid-main .container .signup .input-container .form-container .input-box::placeholder {
  color: #000;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: 'Inter', 'Roboto', sans-serif;
}

.grid-main .container .signup .input-container .input-button {
  margin-top: 5rem;
  width: 100%;
  display: block;
  background-color: #000;
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  padding: 18px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.grid-main .container .signup .input-container button.input-button {
  margin-top: 2rem;
  border: 0;
}

.grid-main .container .signup .input-container .back-link {
  margin-top: 1.5rem;
  text-align: right;
  width: 100%;
  font-size: 1.6rem;
  color: #000;

}

.grid-main .container .signup .input-container .back-link a {
  color: #000;
  text-decoration: underline;
}

.grid-main .register .pictures {
  background-color: #2A3990;
  color: #ffffff;
  font-size: 1.6rem;
  line-height: 2.4rem;
  list-style-position: inside;
  padding-bottom: 5rem;
}

.grid-main .register .pictures img {
  max-width: 500px;
  display: block;
  margin: 0 auto;
}

.grid-main .register .pictures p,
.grid-main .register .pictures ul {
  margin: 0 3rem 0 7.5rem;
}

.grid-main .register .pictures ul li {
  margin-bottom: 1.5rem;
}

.grid-main .register .signup {
  display: grid;
  place-items: center;
  
}

.grid-main .register .signup .input-container {
  margin-top: 0;
  padding: 5rem 0;
}

.grid-main .register .signup .input-container .picture {
  text-align: center;
  position: relative;
}

.grid-main .register .signup .input-container .picture img {
  position: relative;
}

.grid-main .register .signup .input-container .picture img {
  border-radius: 50%;
  height: 60px;
} 

.grid-main .register .signup .input-container .picture input {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 60px;
  height: 160px;
  cursor: pointer;
  opacity: 0;
}

.grid-main .register .signup .input-container .picture .file-button {
  margin-top: 1rem;
  background-color: #2A3990;
  padding: 10px 15px;
  color: #ffffff;
  font-size: 1.2rem;
  display: inline-block;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.navigation-main {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 2rem;
  position: sticky;
  background-color: #ffffff;
  top: 0;
  z-index: 89;
}

.navigation-main .navigation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  padding: 1.5rem 0rem;
  flex-wrap: wrap;
  min-height: 9rem;
}

.navigation-main .navigation-container .profile img {
  width: 60px;
  max-width: 60px;
  height: 60px;
  object-fit: cover;
  display: block;
  border: 1px solid #ABABAB;
  border-radius: 50%;
}

.navigation-main .navigation-container .profile:not(.profile-image-arrow) img:hover {
  outline: 2px solid #2A3990;
}

.navigation-main .navigation-container .profile .profile-image-arrow {
  width: 35px;
  max-width: 35px;
  height: auto;
  object-fit: unset;
  display: block;
  border: none;
  border-radius:0%;
  outline: none !important;
}

.navigation-main .navigation-container .search-bar {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #f4f4f4;
  border-radius: 10px;
  border: 1px solid #ABABAB;
  max-width: 47.5rem;
  margin: 0 5rem 0 2.5rem;
}

.navigation-main .navigation-container .search-bar:focus-within {
  border: 1px solid #f4f4f4;
  outline: 2px solid #2A3990;
}

.navigation-main .navigation-container .search-bar .search-icon {
  width: 25px;
  max-width: 25px;
  margin-right: 10px;
}

.navigation-main .navigation-container .search-bar .search-area {
  width: 100%;
}

.navigation-main .navigation-container .search-bar .search-area input {
  outline: none;
  background-color: #f4f4f4;
  color: #000;
  border: 0;
  width: 100%;
  font-size: 1.4rem;
} 

.navigation-main .navigation-container .navigation-desktop {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

@media screen and (max-width: 600px) { 
  .navigation-main .navigation-container .navigation-desktop {
    justify-content: center;
  }
}


.navigation-main .navigation-container .navigation-desktop .links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  font-weight: 500;
  font-size: 1.6rem;
  margin-right: 3rem;
}

.navigation-main .navigation-container .navigation-desktop .links a {
    color: #808080;
    text-decoration: none;
    position: relative;
}

.navigation-main .navigation-container .navigation-desktop .links a:hover {
  color: rgb(49, 49, 49) !important;
}

.navigation-main .navigation-container .navigation-desktop .links a:hover::before {
  position: absolute;
  content: '';
  width: 35px;
  height: 3px;
  background-color: #bbbbbb;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  bottom: -34px;
  right: 50%;
  transform: translate(50%, 50%);

}



.navigation-main .navigation-container .navigation-desktop .links a.active {
    color: #000;
    text-decoration: none;
    font-weight: 500;
}

.navigation-main .navigation-container .navigation-desktop .links a.active::before {
    position: absolute;
    content: '';
    width: 35px;
    height: 3px;
    background-color: #9C254D;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    bottom: -34px;
    right: 50%;
    transform: translate(50%, 50%);
}

@media screen and (max-width: 600px) { 
  .navigation-main .navigation-container {
    gap: 2rem;
  }

  .navigation-main .navigation-container .navigation-desktop .links a.active::before {
    position: absolute;
    content: '';
    width: 35px;
    height: 3px;
    background-color: #9C254D;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    bottom: -15px;
    right: 50%;
    transform: translate(50%, 50%);
  }

  .navigation-main .navigation-container .navigation-desktop {
    flex: 100%;
  }

  .navigation-main .navigation-container .navigation-desktop .links {
    margin: 0;
    gap: 4rem;
    font-size: 1.3rem;
  }

  .navigation-main .navigation-container .search-bar {
    margin: 0;
    margin-left: 2rem;
  }
}


.navigation-main .navigation-container .navigation-desktop .nav-button .add-project-button {
  font-weight: 500;
  color: #ffffff;
  font-size: 1.6rem;
  background-color: #000;
  padding: 1.5rem 4.5rem;
  border-radius: 10px;
  cursor: pointer;
  transition: 200ms ease-out;
}

.navigation-main .navigation-container .navigation-desktop .nav-button .add-project-button:hover {
  background-color: #000000d8;
}

@media screen and (max-width: 900px) { 
  .add-project-button.mbl-hide {
    display: none;
  }
}


.content-container {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0rem 2rem 4rem 2rem;
  display: flex;
  align-items: flex-start;
  gap: 2.5rem;
}

.content-container .library {
  width: 235px;
  align-self: flex-start;
  background-color: #2A3990;
  border-radius: 10px;
  padding: 2rem;
  padding-bottom: 10rem;
  color: #ffffff;
  font-size: 1.2rem;
  flex: 1 0 20rem;
}

.content-container .library .library-title {
  font-weight: bold;
  margin-bottom: 1rem;
}

.content-container .library .library-category  {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  cursor: pointer;
}

.content-container .library .library-category .category-name,
.categories-container .categories .library-category .category-name {
  font-weight: 300;
  flex: 1;
  text-underline-offset: 2px;
}

.content-container .library .library-category .category-name.active,
.categories-container .categories .library-category .category-name.active {
  font-weight: bold;
  text-decoration: underline;
}

.content-container .library .library-category .category-count,
.categories-container .categories .library-category .category-count {
  width: 28px;
  height: 21px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #2A3990;
  border-radius: 5px;
  font-weight: 400;
}

.content-container .cards-container {
  display: grid;
  gap: 3.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 1000px) { 
  .content-container .cards-container {
    gap: 2.5rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 600px) { 
  .content-container .cards-container {
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
}

.content-container .cards-container .card {
  cursor: pointer;
  border: 1px solid rgba(94, 103, 123, 0.1);
  width:100%;
  position: relative;
  overflow-y: hidden;
}

.content-container .cards-container .card .card-container {
  width: 100%;
  height: 100%;
  position: relative;
  transition: 200ms ease 0s;
}



.content-container .cards-container .card .card-box {
  position: absolute;
  top: -150px;
  width: 100%;
  /* height: 100px; */
  padding: 15px;
  color: #000;
  font-size: 1.4rem;
  background-color: #f2f3f2;
  z-index: 9;
}

.content-container .cards-container .card .card-box .button {
  width: auto;
  background-color: #2A3990;
  border: none;
  color: white;
  padding: 1rem 1.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
}

.content-container .cards-container .card .card-box .card-box-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  justify-content: space-between;
  position: relative;
}

/* .content-container .cards-container .card:hover  {
  filter: brightness(0.7);
  transition: 200ms ease 0s;
} */

.content-container .cards-container .card:hover .card-image  {
  filter: brightness(0.6);
  transition: filter 200ms ease-out 0s;
  background-color: #f2f3f2;
}

.content-container .cards-container .card:hover .card-box {
  top: 0;
  filter: none;
  transition: 200ms ease 0s;
}


.content-container .cards-container .card img {
  width: 100%;
  height: 500px;
  object-fit: contain;
  object-position: top;
}

.content-container .cards-container .card .card-container .card-details-icon {
  position: absolute;
  display: none;
  color: #fff;
  text-align: center;
  margin-top: 10px;
  font-size: 1.1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-container .cards-container .card .card-container .card-details-icon img {
  width: 36px;
  height: auto;
  object-fit: unset;
}

.content-container .cards-container .card .card-container:hover .card-details-icon {
  display: block;
}

.categories-container {
  height: 6rem;
  max-width: 1340px;
  padding: 1rem 2rem;
  overflow: hidden;
}

@media screen and (max-width: 1200px) { 
  .categories-container {
    padding: 1rem 0rem;
  }

}

.categories-container .categories {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #2A3990;
  border-radius: 5px;
  padding: 1rem;
  color: #ffffff;
  font-size: 1.2rem;
  gap: 1.5rem;
  overflow: auto;
}


.categories-container .categories .library-title {
  margin-right: 0.5rem;
}

.categories-container .categories .library-category  {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  cursor: pointer;
}

.categories-container .categories .library-title {
  font-weight: bold;
}


@media screen and (max-width: 1200px) { 
  .library {
    display: none !important;
  }
} 


.profile-container {
  max-width: 1340px;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  margin-top: 4rem;
}

.profile-container .profile-content {
  height: 25rem;
  background-color: #F4F4F4;
  margin-bottom: 12rem;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
}

.profile-container .profile-content .profile-card {
  display: flex;
  align-items: flex-end;
  margin-bottom: -8.5rem;
  margin-left: 8.5rem;
  gap: 4.5rem;
}

.profile-container .profile-content .profile-card .profile-image {
  max-width: 15rem;
  min-height: 15rem;
  min-width: 15rem;
  width: 100%;
  background-color: #F4F4F4;
  border-radius: 50%;
}

.profile-container .profile-content .profile-card .profile-image img {
  width: 100%;
  display: block;
  height: 15rem;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid white;
}

.profile-container .profile-content .profile-card .profile-info {
  font-size: 1.8rem;
  color: #808080;
  margin-bottom: 1rem;
}

.profile-container .profile-content .profile-card .profile-info .profile-name{
  color: #000;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.profile-container .profile-content .profile-card .profile-info .profile-count{
  display: flex;
  align-items: center;
  gap: 5rem;
}

@media screen and (max-width: 1000px) { 
  .profile-container .profile-content .profile-card .profile-info .profile-count{
    flex-direction: column;
    align-items: baseline;
    gap: 2rem;
  }

  .profile-container .profile-content .profile-card {
    display: flex;
    align-items: flex-end;
    margin-bottom: -8.5rem;
    margin-left: 3rem;
    gap: 4.5rem;
  }
}

@media screen and (max-width: 600px) { 

  .profile-container .profile-content .profile-card {
    align-items: center;
  }

  .profile-container .profile-content {
    justify-content: center;
  }

  .profile-container .profile-content .profile-card {
    margin-left: 0;
  }

  .profile-container .profile-content .profile-card {
    flex-direction: column;
  }

  .profile-container .profile-content {
    height: 40rem;
  }


}


.profile-container .profile-content .profile-card .profile-info .profile-count strong{
  color: #000;
  font-weight: 600;
}

.library-container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  border-top: 2px solid #D9D9D9;
  font-size: 1.8rem;
  color: #808080;
  padding: 0 2rem 6rem 2rem;
}

.library-container .library-title {
  color: #000;
  font-weight: 500;
  margin-top: 4.5rem;
  padding-bottom: 1rem;
  border-bottom: 3px solid #2A3990;
  display: inline-block;
  margin-bottom: 3rem;
}

.library-container .library-content {
  display: grid;
  /* flex-wrap: wrap; */
  gap: 3rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: baseline;
}

@media screen and (max-width: 1000px) { 
  .library-container .library-content {
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }

  .library-container .library-content .library-project {
    max-width: 100% !important;
  }

}

@media screen and (max-width: 600px) { 
  .library-container .library-content {
    grid-template-columns: 1fr;
  }

}

.library-container .library-content .library-project {
  cursor: pointer;
  max-width: 30rem;
  border: 1px solid rgba(94, 103, 123, 0.1);
  position: relative;
}

.library-container .library-content .library-project .library-project-text {
  font-size: 1.4rem;
  color: #000;
  margin: 2rem;
  border-bottom: 1px solid black;
  padding-bottom: 1rem;
  cursor: initial;
}

.library-container .library-content .library-project .library-project-text p {
  margin-bottom: 1rem;
}

.library-container .library-content .library-project .library-project-icon {
  position: absolute;
  display: none;
  color: #fff;
  text-align: center;
  margin-top: 10px;
  font-size: 1.1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.library-container .library-content .library-project:hover .library-project-icon {
  display: block;
}


.library-container .library-content .library-project .library-project-image img {
  width: 100%;
  object-fit: cover;

}

.library-container .library-content .library-project:hover .library-project-image {
  filter: brightness(0.6);
  transition: filter 200ms ease-out 0s;
  background-color: #f2f3f2;
}



.profile-logout .add-project-button {
  font-weight: 500;
  color: #ffffff;
  font-size: 1.6rem;
  background-color: #000;
  padding: 1.5rem 4.5rem;
  border-radius: 10px;
  cursor: pointer;
  transition: 200ms ease-out all;
}

.profile-logout .add-project-button:hover {
  background-color: #000000d8;
}

.emPage {
  width: 1340px;
  margin: 0 auto;
}

.emailPage-container {
  width: 1340px;
  margin: 0 auto;
  padding: 0rem 2rem 4rem 2rem;
  display: grid;
  grid-template-columns: 8fr 2fr;
  gap: 3.5rem;
  min-height: 83vh;
  /* position: relative; */
}

.emailPage-container .emailPage-main,
.emailPage-container .emailPage-sidemenu {
  border-radius: 10px;
  background-color: #F4F4F4;
}

.pagehref {
  width: 1340px;
  margin: 0 auto;
  padding: 2rem 2rem 2rem 2rem;
  font-size: 1.6rem;
  color: #808080;
  position: sticky;
  top: 90px;
  background-color: #ffffff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagehref .count-page {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
}

.pagehref .pagehref-text span {
  color: #2A3990;
  font-weight: bold;
}

.emailPage-container .emailPage-main {
  display: flex;
  justify-content: center;
  align-items: top;
  padding: 4.5rem 0;
  position: relative;
}

.emailPage-container .emailPage-main .emailPage-main-box {
  max-width: 700px;
}

.emailPage-container .emailPage-main .emailPage-main-box iframe {
  margin: 0 auto;  
}


.emailPage-container .emailPage-main .emailPage-main-view {
  position: absolute;
  top: 0;
  right: 0;
}

.emailPage-container .emailPage-sidemenu {
  min-height: 30rem;
  align-self: flex-start;
  position: sticky;
  top: 150px;
}

.emailPage-container .emailPage-sidemenu .emailPage-buttons {
  padding: 0 1.5rem;
}
.emailPage-loading,
.emailPage-loading svg {
  height: 100% !important;
  /* background-color: #5a5a5a; */
  width: 100% !important;
  transition: 4s ease-out;
}
.buttonView {
  background-color: #2A3990;
}

.red {
  background-color: #9C254D;
}

.green {
  background-color: green;
}

/* .cm-content {
  width: 700px !important;
  height: 75vh !important;
  overflow: scroll !important;
  font-size: 1.2rem !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
} */

.cm-editor {
  width: 700px;
  max-height: 65vh;
  overflow-y: auto;
}

.goBack {
  cursor: pointer;
}

.user_info {
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid rgba(94, 103, 123, 0.1);
  margin-top: 2rem;
  border-radius: 10px;
}

.user_created {
  font-size: 1.2rem;
  color: #808080;
  display: flex;
  align-items: center;
  gap: 1rem;
}


.user_created img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  display: block;
  border-radius: 50%;
}













